import Sortable from 'sortablejs';
import axios from 'axios'

const adminSortable = document.querySelectorAll('.sortable')

if (adminSortable.length > 0) {
	adminSortable.forEach(e => {
		let sortable = new Sortable(e, {
			animation: 150,
			ghostClass: 'opacity-40',
			filter: '.form-group, .btn',
			preventOnFilter: false,
			store: {
				set(sortable) {
					const children = e.querySelectorAll('li')
					let ids = []
					let order = null

					for (let [index, values] of children.entries()) {
						if (children[index].dataset.id) {
							ids.push(children[index].dataset.id)
						}
					}

					order = ids.join(';')

					const routeReorder = e.dataset.url ? e.dataset.url : `${window.Route}/reorder`

					axios.get(routeReorder, { params: { order: order } })
						.then(res => {
							e.parentElement.querySelector('.alert').innerHTML = 'Itens reordenados com sucesso!'
							e.parentElement.querySelector('.alert').classList = 'alert alert-success py-2 px-3 mb-3'
						})
						.catch(error => {
							e.parentElement.querySelector('.alert').innerHTML = 'Erro ao tentar reordenar itens!'
							e.parentElement.querySelector('.alert').classList = 'alert alert-danger py-2 px-3 mb-3'
							console.warn('Error: Trying to order data')
							console.error(error)
						})
				}
			}
		})
	})
}

// delete files
const adminDeleteNewsFile = document.querySelectorAll('.delete-news-file')

if (adminDeleteNewsFile.length > 0) {
	adminDeleteNewsFile.forEach(e => {
		e.addEventListener('click', event => {
			if (confirm('Deseja mesmo remover este arquivo?')) {
				
				axios.get(e.dataset.url)
					.then(res => {
						e.parentElement.parentElement.remove()
					})
					.catch(err => {
						alert('Erro ao tentar remover arquivo.')
						console.error(err)
					})
			}
		})
	})
}

// delete images
const adminDeleteNewsImage = document.querySelectorAll('.delete-news-image')

if (adminDeleteNewsImage.length > 0) {
	adminDeleteNewsImage.forEach(e => {
		e.addEventListener('click', event => {
			if (confirm('Deseja mesmo remover esta imagem?')) {
				
				axios.get(e.dataset.url)
					.then(res => {
						e.parentElement.parentElement.parentElement.remove()
					})
					.catch(err => {
						alert('Erro ao tentar remover imagem.')
						console.error(err)
					})
			}
		})
	})
}

// copy links
const adminCopyUrl = document.querySelectorAll('.copy-url')

if (adminCopyUrl.length > 0) {
	adminCopyUrl.forEach(e => {
		e.addEventListener('click', async event => {
			try {
				if (!e.dataset.url) throw new Error()

				await navigator.clipboard.writeText(e.dataset.url)

				e.classList.add('is--success')
				e.querySelector('.material-icons').innerHTML = 'check'

				setTimeout(() => {
					e.classList.remove('is--success')
					e.querySelector('.material-icons').innerHTML = 'content_copy'
				}, 1000)
			} catch (err) {
				alert('Erro ao tentar copiar link')
				console.error('Failed to copy: ', err)
			}
		})
	})
}
